<div *ngIf="loading$ | async" class="spinner-overlay">
  <div class="spinner">
    <mat-spinner [value]="50" [diameter]="50"></mat-spinner>
  </div>
</div>

<form *ngIf="couponForm" [formGroup]="couponForm" class="coupon-container">
  <div class="summary" *ngIf="couponId && adminUserDetails">
    <div class="row1">
      <span>Redeem State: {{ redeemState }} </span>
      <span>Available Codes: {{ availableCodes }}</span>
    </div>

    <div class="row2">
      <div>
        <span>Created By: {{ adminUserDetails?.createdBy ?? '-' }} </span>
        <span>Created At: {{ adminUserDetails?.createdAt | date: ' dd.MM.yyyy, HH:mm:ss' }}</span>
      </div>

      <div>
        <span>Last Modified By: {{ adminUserDetails?.modifiedBy ?? '-' }} </span>
        <span>Last Modified At: {{ adminUserDetails?.modifiedAt | date: ' dd.MM.yyyy, HH:mm:ss' }}</span>
      </div>
    </div>
  </div>
  <div id="couponDetails" class="coupon-info-border">
    <div class="coupon-form-field mat-field">
      <mat-label class="coupon-form-field-label">Coupon Name</mat-label>
      <mat-form-field appearance="outline">
        <input formControlName="name" matInput placeholder="Campaign 2022" />
        <mat-error *ngIf="name.errors?.required">This is a mandatory field. Please update and retry.</mat-error>
        <mat-error *ngIf="name.invalid && !name.errors?.required"
          >The input does not match with the defined criteria. Please update the entry.</mat-error
        >
      </mat-form-field>
    </div>

    <div class="coupon-form-field">
      <mat-label class="coupon-form-field-label">Type</mat-label>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="couponType"
        [ngClass]="{ 'disabled-field': isEditMode }"
        (change)="changeCouponType(couponType.value)"
      >
        <mat-radio-button value="{{ couponTypes.Multi }}" style="padding-right: 20px">Auto generate</mat-radio-button>
        <mat-radio-button value="{{ couponTypes.Single }}" style="padding-right: 20px">Manual</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="coupon-form-field mat-field">
      <div *ngIf="couponType.value === couponTypes.Single" class="extra-padding">
        <mat-label class="coupon-form-field-label">Code</mat-label>
        <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isEditMode }">
          <input formControlName="manualCodes" matInput placeholder="SALE2022" />
          <mat-error *ngIf="manualCodes.errors?.required"
            >This is a mandatory field. Please update and retry.</mat-error
          >
          <mat-error *ngIf="manualCodes.invalid && !manualCodes.errors?.required"
            >Code should have minimum of 6 characters, should not be longer then 20 and only include numbers and
            lowercase letters
          </mat-error>
        </mat-form-field>
        <br />
      </div>

      <div *ngIf="couponType.value === couponTypes.Multi" class="extra-padding">
        <mat-label class="coupon-form-field-label">Nr of codes to generate</mat-label>
        <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isEditMode }">
          <input
            (blur)="confirmNoOfCodes()"
            type="number"
            matInput
            placeholder="100"
            formControlName="noOfCodes"
            digitOnly
          />
          <mat-error *ngIf="noOfCodes.invalid"
            >Please add a number into the field before generating the codes.</mat-error
          >
          <mat-error *ngIf="noOfCodes.invalid">This number must be bigger than 0 and less then 100'000</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div id="couponEffect" class="coupon-info-border">
    <div>
      <span class="coupon-subtitle">Coupon Effect</span>
    </div>

    <div class="coupon-effect">
      <div class="coupon-column">
        <mat-label class="coupon-form-field-label extra-margin">Type</mat-label>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="effectType"
          (change)="changeCouponEffectType(effectType.value, false)"
          [disabled]="isEditMode"
          [ngClass]="{ 'disabled-field': isEditMode }"
        >
          <mat-radio-button value="{{ couponEffectTypes.Fixed }}" style="padding-right: 20px"
            >Fixed Amount</mat-radio-button
          >
          <mat-radio-button value="{{ couponEffectTypes.Percentage }}" style="padding-right: 20px"
            >Percentage</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="coupon-column mat-field">
        <div *ngIf="effectType.value === couponEffectTypes.Fixed">
          <mat-label class="coupon-form-field-label">Amount (CHF)</mat-label>
          <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponRedeemed }">
            <input
              type="number"
              matInput
              placeholder="000.00"
              formControlName="effectValue"
              step="any"
              digitOnly
              [decimal]="true"
            />
            <mat-error *ngIf="effectValue.errors?.required"
              >This is a mandatory field. Please update and retry.</mat-error
            >
            <mat-error *ngIf="effectValue.invalid && !effectValue.errors?.required"
              >The fixed amount must be between 0.01 and 500 and bigger than the "Min Purchase Amount"</mat-error
            >
          </mat-form-field>
        </div>

        <div *ngIf="effectType.value === couponEffectTypes.Percentage">
          <mat-label class="coupon-form-field-label">Amount (%)</mat-label>
          <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponRedeemed }">
            <input
              type="number"
              matInput
              placeholder="00.00"
              formControlName="effectValue"
              digitOnly
              [decimal]="true"
            />
            <mat-error *ngIf="effectValue.errors?.required"
              >This is a mandatory field. Please update and retry.</mat-error
            >
            <mat-error *ngIf="effectValue.invalid && !effectValue.errors?.required"
              >The percentage value must be between 0.01 and 100</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="finance-section coupon-info-border" [ngClass]="{ 'disabled-pointer-events': isCouponArchived }">
    <div class="finance-title">
      <h3>Finance</h3>
      <button
        type="button"
        (click)="addFinanceParty()"
        class="add-finance-party"
        mat-button
        [ngClass]="{ 'disabled-field': isCouponArchived }"
      >
        <span>Add Finance Party</span>
      </button>
    </div>

    <div class="finance-parties" [ngClass]="{ 'disabled-field': isCouponArchived }">
      <mat-form-field appearance="outline">
        <mat-label>Finance party 1</mat-label>
        <mat-select formControlName="financingPartyName1">
          <mat-option *ngFor="let party of financingParties$ | async" [value]="party">
            {{ party }}
          </mat-option>
          <mat-option disabled><hr /></mat-option>
          <mat-option [value]="null">None</mat-option>
          <!-- Unselect option -->
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Finance party 2</mat-label>
        <mat-select formControlName="financingPartyName2">
          <mat-option *ngFor="let party of financingParties$ | async" [value]="party">
            {{ party }}
          </mat-option>
          <mat-option disabled><hr /></mat-option>
          <mat-option [value]="null">None</mat-option>
          <!-- Unselect option -->
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Finance party 3</mat-label>
        <mat-select formControlName="financingPartyName3">
          <mat-option *ngFor="let party of financingParties$ | async" [value]="party">
            {{ party }}
          </mat-option>
          <mat-option disabled><hr /></mat-option>
          <mat-option [value]="null">None</mat-option>
          <!-- Unselect option -->
        </mat-select>
      </mat-form-field>
    </div>

    <h4>Cost Split Type</h4>

    <div class="cost-split-type">
      <mat-radio-group
        class="flex-row-4"
        formControlName="costSplitType"
        [ngClass]="{ 'disabled-field': isCouponArchived }"
      >
        <mat-radio-button value="Fixed">Fixed Amount</mat-radio-button>
        <mat-radio-button value="Percentage">Percentage</mat-radio-button>
      </mat-radio-group>
    </div>
    <br />

    <h4>Cost Split Distribution</h4>
    <div class="cost-split-distribution">
      <div class="party-distribution">
        <div class="flex-row-4">
          <span>Finance party 1</span>
          <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponArchived }">
            <mat-label>Amount (CHF) or %</mat-label>
            <input
              matInput
              type="number"
              placeholder="Enter amount or %"
              formControlName="financingPartyAmount1"
              [step]="0.01"
            />
            <mat-error *ngIf="couponForm.controls['financingPartyAmount1'].hasError('pattern')">
              Please enter a valid amount with up to 2 decimal places.
            </mat-error>
          </mat-form-field>
        </div>
        <p>Finance party 1 current cost: {{ couponFinancingPartyTotal[0].toFixed(2) ?? 0 }} CHF</p>
      </div>

      <div class="party-distribution">
        <div class="flex-row-4">
          <span>Finance party 2</span>
          <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponArchived }">
            <mat-label>Amount (CHF) or %</mat-label>
            <input
              matInput
              type="number"
              placeholder="Enter amount or %"
              formControlName="financingPartyAmount2"
              [step]="0.01"
            />
            <mat-error *ngIf="couponForm.controls['financingPartyAmount2'].hasError('pattern')">
              Please enter a valid amount with up to 2 decimal places.
            </mat-error>
          </mat-form-field>
        </div>
        <p>Finance party 2 current cost: {{ couponFinancingPartyTotal[1].toFixed(2) ?? 0 }} CHF</p>
      </div>

      <div class="party-distribution">
        <div class="flex-row-4">
          <span>Finance party 3</span>
          <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponArchived }">
            <mat-label>Amount (CHF) or %</mat-label>
            <input
              matInput
              type="number"
              placeholder="Enter amount or %"
              formControlName="financingPartyAmount3"
              [step]="0.01"
            />
            <mat-error *ngIf="couponForm.controls['financingPartyAmount3'].hasError('pattern')">
              Please enter a valid amount with up to 2 decimal places.
            </mat-error>
          </mat-form-field>
        </div>
        <p>Finance party 3 current cost: {{ couponFinancingPartyTotal[2].toFixed(2) ?? 0 }} CHF</p>
      </div>
    </div>

    <div class="comments-section" [ngClass]="{ 'disabled-field': isCouponArchived }">
      <mat-form-field appearance="outline" class="comments-field">
        <mat-label>Comments</mat-label>
        <textarea matInput rows="4" formControlName="comment"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div id="couponCriteria" class="coupon-info-border">
    <div>
      <span class="coupon-subtitle">Coupon Criteria</span>
    </div>
    <mat-error class="formgroup-error" *ngIf="couponForm.invalid && couponForm?.errors?.minPurchaseAmountToSmall"
      >Min purchase amount must be greater or equal amount if you select a fixed amount.</mat-error
    >

    <div>
      <mat-label class="coupon-form-field-label">Products</mat-label>

      <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponRedeemed }">
        <mat-select
          formControlName="products"
          multiple
          *ngIf="whiteListedProducts"
          placeholder="Please Select"
          [disabled]="isCouponRedeemed"
        >
          <mat-select-trigger *ngIf="!allProductsSelected.selected">
            {{products.value?.[0] || ''}}
            <span>
              (+{{ (products.value?.length || 0) - 1 }} {{ products.value?.length === 2 ? 'other' : 'others' }})
            </span>
          </mat-select-trigger>

          <mat-select-trigger *ngIf="allProductsSelected.selected"> All Products </mat-select-trigger>

          <mat-option #allProductsSelected (click)="toggleAllProductSelection()" [value]="0">All</mat-option>

          <mat-option
            *ngFor="let whiteListedProduct of whiteListedProducts"
            [value]="whiteListedProduct.productLineName"
            (click)="unselectAllProductsIfNeeded()"
            >{{ whiteListedProduct.productLineName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="coupon-criteria">
      <div class="coupon-column">
        <mat-label class="coupon-form-field-label">Min Purchase Amount</mat-label>
        <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponRedeemed }">
          <input
            type="number"
            matInput
            placeholder="00.00"
            formControlName="minPurchaseAmount"
            digitOnly
            [decimal]="true"
          />
          <mat-error *ngIf="minPurchaseAmount.invalid"
            >The input does not match with the defined criteria. Please update the entry.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="coupon-column">
        <mat-label class="coupon-form-field-label">Max applications per user</mat-label>
        <mat-form-field
          appearance="outline"
          [ngClass]="{ 'disabled-field': couponType.value === couponTypes.Multi || isCouponRedeemed }"
        >
          <input type="number" matInput placeholder="0" formControlName="maxApplicationPerUser" digitOnly />
          <mat-error *ngIf="maxApplicationPerUser.invalid"
            >The input does not match with the defined criteria. Please update the entry.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="coupon-column">
        <mat-label class="coupon-form-field-label">Max applications of all users</mat-label>
        <mat-form-field
          appearance="outline"
          [ngClass]="{ 'disabled-field': couponType.value === couponTypes.Multi || isCouponRedeemed }"
        >
          <input type="number" matInput placeholder="0" formControlName="maxApplicationOverAllUsers" digitOnly />
          <mat-error *ngIf="maxApplicationOverAllUsers.invalid"
            >The input does not match with the defined criteria. Please update the entry.</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div>
      <div>
        <span class="coupon-subtitle">Valid from / to</span>
      </div>

      <mat-form-field appearance="outline" [ngClass]="{ 'disabled-field': isCouponRedeemed }">
        <mat-label>Start Date</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" formControlName="startFrom" />
        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSeconds]="showSeconds" [defaultTime]="defaultTime">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field style="margin-left: 1rem" appearance="outline" [ngClass]="{ 'disabled-field': isCouponRedeemed }">
        <mat-label>End Date</mat-label>
        <input [min]="startFrom.value" matInput [ngxMatDatetimePicker]="endpicker" formControlName="endAt" />
        <mat-datepicker-toggle matSuffix [for]="$any(endpicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #endpicker [showSeconds]="showSeconds" [defaultTime]="defaultTime">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-error class="formgroup-error" *ngIf="couponForm.invalid && couponForm?.errors?.invalidDates">
        The coupons should be valid between 31.12.2022 and 31.12.2032 and start date/time must before end date/time.
      </mat-error>
    </div>
  </div>

  <div *ngIf="isEditMode" class="coupon-info-border">
    <div *ngIf="couponType.value === couponTypes.Single; else multi">
      <span>Code</span>
      <span class="coupon-subtitle">{{ manualCodes?.value }}</span>
    </div>

    <ng-template #multi>
      <span class="coupon-subtitle">Codes</span>
    </ng-template>

    <div id="couponCodesGeneration" class="coupon-codes-generation" *ngIf="couponType.value === couponTypes.Multi">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">{{ element.code }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>E-mail</th>
          <td mat-cell *matCellDef="let element">{{ element.email ?? '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef>User UUID</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.userId !== null && element.userId !== undefined && element.userId !== '' ? element.userId : '-'
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Redemption Time</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.time !== null && element.time !== undefined ? (element.time | date: ' dd.MM.yyyy, HH:mm:ss') : '-'
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let element">{{ couponJournalEnumMapper[element.state] | lowercase }}</td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.state === couponJournalEnum.CANCELED || element.state === couponJournalEnum.CREATED">
              <a class="invalidate-code-href" (click)="invalidateCode(element.code)">invalidate code</a>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsForAutoGeneratedCodes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsForAutoGeneratedCodes"></tr>
      </table>

      <mat-paginator
        style="background-color: transparent"
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (page)="onPageChange($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>

    <div id="couponCodesGeneration" class="coupon-codes-generation" *ngIf="couponType.value === couponTypes.Single">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>E-mail</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef>User UUID</th>
          <td mat-cell *matCellDef="let element">{{ element.userId }}</td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Redemption Time</th>
          <td mat-cell *matCellDef="let element">{{ element.time | date: ' dd.MM.yyyy, HH:mm:ss' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsForManualCodes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsForManualCodes"></tr>
      </table>

      <mat-paginator
        *ngIf="couponCodes.length !== 0"
        style="background-color: transparent"
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (page)="onPageChange($event)"
        showFirstLastButtons
      >
      </mat-paginator>

      <div
        [hidden]="couponCodes.length !== 0 && couponCodes[0].state === couponJournalEnum.CONFIRMED"
        class="no-codes-msg"
      >
        <span class="no-codes-msg">No transactions are done yet!</span>
      </div>
    </div>
  </div>

  <div class="codes-manipulation" *ngIf="isEditMode">
    <button type="button" (click)="controlCoupon()" class="control-button" mat-button [disabled]="isCouponControlled">
      <span>Control Coupon</span>
    </button>
    <ng-container *ngIf="couponType.value === couponTypes.Multi">
      <button
        type="button"
        *ngIf="showInvalidateCodesBtn"
        id="addCoupon"
        class="invalidate-button"
        (click)="invalidateCodes()"
      >
        <span>Invalidate Codes</span>
      </button>
      <button type="button" (click)="downloadCodes()" class="download-button" mat-button>
        <span>Download Shown Codes</span>
      </button>
    </ng-container>
  </div>

  <div style="text-align: center">
    <button
      type="button"
      (click)="onSaveClick()"
      [disabled]="couponForm.invalid || isViewMode || isFormSubmitted"
      color="success"
      mat-button
    >
      Save
    </button>
    <button type="button" (click)="navigateBackToList()" mat-raised-button style="margin-left: 10px">Cancel</button>
  </div>
</form>

<ng-container *ngIf="error">
  <div class="d-flex justify-content-center overlay"></div>

  <div class="popup-overlay text-center">
    <hr style="margin-top: 1em; margin-bottom: 1em" />
    <mat-dialog-content class="mat-typography">
      <p>
        {{ error }}
      </p>
      <button type="button" class="btn-ok mt-2" (click)="error = undefined">Ok</button>
      <div style="margin-bottom: 1em"></div>
    </mat-dialog-content>
  </div>
</ng-container>
