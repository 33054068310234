/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { couponInitValue } from './coupon';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import {
  CouponEffectTypeEnum,
  CouponTypeEnum,
  CouponCodeDTO,
  CouponJournalEnum,
  RedeemStateEnum,
  UpdateCouponDTO,
  DetailedCouponResponseDTO,
  CreateCouponDTO,
} from '@modeso/types__twint-lib-coupons';
import { Observable, Subject, Subscription, combineLatest, map, of, switchMap, take, takeUntil } from 'rxjs';
import { filter, tap } from 'rxjs';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../..//util/dateFormats.helper';
import { CouponErrorDialog } from '../../dialog/errorDialog/errorDialog';
import { ConfirmDeletionDialog } from '../../dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmationDialog } from '../../dialog/confirmationDialog/confirmationDialog';
import { ModificationData } from '../../models/interfaces/modification-data.interface';
import equal from 'fast-deep-equal';
import { AdminUsersResponse } from '../../../../../dgoods-lib-admin-fe/src/lib';
import { IFinancingParty } from '../../models/interfaces/finance';
import { AddFinancingPartyDialog } from '../../dialog/addFinancingParty/addFinancingParty';
import { UserProvider } from '../../../../../dgoods-lib-user-fe/src/lib';
import { CouponCodeRedemptionDetails } from '../../models/interfaces/coupon-code-redemption-details.interface';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { InvalidateCodeRequest } from '../../models/interfaces/invalidate-code-request.interface';
import { InvalidateCodesRequest } from '../../models/interfaces/invalidate-codes-request.interface';
import { CouponForm } from '../../models/interfaces/coupon-form.interface';
import { DialogData } from '../../models/interfaces/dialog-data.interface';
import { HttpErrorResponse } from '@angular/common/http';

const MAX100_Validator = Validators.max(100.0);
const MAX500_Validator = Validators.max(500.0);
const FINANCING_PARTIES_COUNT = 3;

@Component({
  selector: 'app-add-coupon-component',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class AddCouponComponent {
  // Angular Decorators
  @Input() couponId: string | undefined;
  @Input() adminUsers$: Observable<AdminUsersResponse>;
  @Input() productsObservable$: Observable<string[]> | undefined;
  @Output() redirectToList: EventEmitter<void> = new EventEmitter();
  @ViewChild('allProductsSelected') private readonly allProductsSelected: MatOption;
  @ViewChild(MatPaginator) private readonly paginator: MatPaginator | undefined;

  // Public Observables
  financingParties$: Observable<string[]> = of([]);
  loading$: Observable<boolean>;

  // Public Properties
  displayedColumnsForAutoGeneratedCodes: string[] = ['code', 'email', 'userId', 'time', 'state', 'options'];
  displayedColumnsForManualCodes: string[] = ['email', 'userId', 'time'];
  couponForm: FormGroup;
  showSeconds = false;
  defaultTime = [0, 0];
  isEditMode = false;
  isViewMode = false;
  isCouponRedeemed = false;
  isFormSubmitted = false;
  isCouponControlled: boolean = false;
  error = '';
  couponCodes: CouponCodeRedemptionDetails[] = [];
  dataSource = new MatTableDataSource<CouponCodeRedemptionDetails>([]);
  showInvalidateCodesBtn = false;
  whiteListedProducts: string[] = [];
  redeemState = RedeemStateEnum[RedeemStateEnum.OPEN];
  availableCodes = -1;
  adminUserDetails: ModificationData;
  financingParties: IFinancingParty[] = [];
  couponFinancingPartyTotal: number[] = [0, 0, 0];
  isCouponArchived = false;
  totalRecords = 0;
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions = [5, 100, 1000, 10000, 25000, 50000];

  // Private Observables and Subjects
  private readonly userTokens$ = new BehaviorSubject<string[]>([]); // Observable of user tokens
  private readonly destroy$ = new Subject<void>();

  // Private Subscriptions
  private initialCoupon: UpdateCouponDTO | undefined;
  private userTokensSubscription: Subscription | undefined;
  private userEmailsSubscription: Subscription | undefined;

  private getCouponDetails: Subscription | undefined;
  private getErrors: Subscription | undefined;
  private productsSubscription: Subscription | undefined;

  private isFormInitialized = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly couponProvider: TwintCouponsProvider,
    private readonly usersProvider: UserProvider,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {

    this.onInitSubscriptions();

    if (this.couponId != null) {
      this.initEditCouponById();
    } else {
      this.initCouponForm(couponInitValue);
    }

    this.couponProvider.dispatchGetFinancingParties();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.getCouponDetails != null) {
      this.getCouponDetails.unsubscribe();
    }

    if (this.getErrors != null) {
      this.getErrors.unsubscribe();
      this.couponProvider.flushError();
    }

    if (this.productsSubscription != null) {
      this.productsSubscription.unsubscribe();
    }

    if (this.userTokensSubscription != null) {
      this.userTokensSubscription.unsubscribe();
    }

    if (this.userEmailsSubscription != null) {
      this.userEmailsSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();

  }

  public get name(): AbstractControl | null {
    return this.couponForm?.get('name');
  }

  public get couponType() : AbstractControl | null{
    return this.couponForm?.get('couponType');
  }

  public get manualCodes() : AbstractControl | null {
    return this.couponForm?.get('manualCodes');
  }

  public get noOfCodes(): AbstractControl | null {
    return this.couponForm?.get('noOfCodes');
  }

  public get effectType() : AbstractControl | null {
    return this.couponForm?.get('effectType');
  }

  public get effectValue(): AbstractControl | null {
    return this.couponForm?.get('effectValue');
  }

  public get products(): AbstractControl | null {
    return this.couponForm?.get('products');
  }

  public get minPurchaseAmount(): AbstractControl | null {
    return this.couponForm?.get('minPurchaseAmount');
  }

  public get maxApplicationPerUser(): AbstractControl | null {
    return this.couponForm?.get('maxApplicationPerUser');
  }

  public get maxApplicationOverAllUsers(): AbstractControl | null {
    return this.couponForm?.get('maxApplicationOverAllUsers');
  }

  public get startFrom(): AbstractControl | null{
    return this.couponForm?.get('startFrom');
  }

  public get endAt(): AbstractControl | null {
    return this.couponForm?.get('endAt');
  }

  public get state(): AbstractControl | null {
    return this.couponForm?.get('state');
  }

  public get costSplitType(): AbstractControl | null {
    return this.couponForm?.get('costSplitType');
  }

  public get financingPartyName1(): AbstractControl | null {
    return this.couponForm?.get('financingPartyName1');
  }

  public get financingPartyAmount1() : AbstractControl | null {
    return this.couponForm?.get('financingPartyAmount1');
  }

  public get financingPartyName2(): AbstractControl | null {
    return this.couponForm?.get('financingPartyName2');
  }

  public get financingPartyAmount2(): AbstractControl | null {
    return this.couponForm?.get('financingPartyAmount2');
  }

  public get financingPartyName3() : AbstractControl | null{
    return this.couponForm?.get('financingPartyName3');
  }

  public get financingPartyAmount3(): AbstractControl | null {
    return this.couponForm?.get('financingPartyAmount3');
  }

  public get comment() : AbstractControl | null{
    return this.couponForm?.get('comment');
  }

  public get couponTypes(): typeof CouponTypeEnum {
    return CouponTypeEnum;
  }

  public get couponEffectTypes(): typeof CouponEffectTypeEnum {
    return CouponEffectTypeEnum;
  }

  public get couponJournalEnum(): typeof CouponJournalEnum {
    return CouponJournalEnum;
  }

  public get couponJournalEnumMapper(): typeof CouponJournalEnum {
    const journalEnum = { ...CouponJournalEnum };
    journalEnum[journalEnum.CANCELED] = 'PAYMENT CANCELLED';
    return journalEnum;
  }

  toggleAllProductSelection(): void {
    if (this.allProductsSelected.selected) {
      this.products.patchValue([0]);
    } else {
      this.products.patchValue([...this.products.value]);
    }
  }

  unselectAllProductsIfNeeded(): void {
    if (this.allProductsSelected.selected) {
      this.allProductsSelected.deselect();
    }
  }

  changeCouponEffectType(couponEffectType: CouponEffectTypeEnum, onInit = true): void {
    // remove the effect value in case of toggling effect type only in add mode
    if (onInit === false && this.couponId == null && this.effectValue.value != null) {
      this.effectValue.setValue('');
    }
    if (couponEffectType === CouponEffectTypeEnum.Fixed) {
      this.effectValue.removeValidators(MAX100_Validator);
      this.effectValue.addValidators(MAX500_Validator);
    } else {
      // clear validator
      this.effectValue.removeValidators(MAX500_Validator);
      this.effectValue.addValidators(MAX100_Validator);
    }
    this.effectValue?.updateValueAndValidity();
  }

  confirmNoOfCodes(): void {

    if (this.noOfCodes.valid && !this.isEditMode) {
      const data: DialogData = {
        text: `You have selected to generate ${this.noOfCodes.value} codes. Once the codes are generated this field will no longer be editable. <br><br> Are you sure?`,
        type: 2,
      };

      this.dialog.open(ConfirmationDialog, {
        data,
      });
    }

  }

  changeCouponType(couponType: CouponTypeEnum): void {
    if (couponType === CouponTypeEnum.Multi) {
      this.maxApplicationOverAllUsers.setValue(1);
      this.maxApplicationPerUser.setValue(1);
      this.noOfCodes.addValidators(Validators.required);
      this.manualCodes.removeValidators(Validators.required);
      this.maxApplicationOverAllUsers.disable();
      this.maxApplicationPerUser.disable();
    } else {
      this.maxApplicationOverAllUsers.setValue(1);
      this.maxApplicationPerUser.setValue(0);
      this.noOfCodes.removeValidators(Validators.required);
      this.manualCodes.addValidators(Validators.required);
      this.maxApplicationPerUser.enable();
      this.maxApplicationOverAllUsers.enable();
    }
    this.manualCodes.updateValueAndValidity();
    this.noOfCodes.updateValueAndValidity();
  }

  invalidateCode(code: string): void {
    const { skip, limit } = this.getSkipAndLimit();

    const invalidateCodeRequest: InvalidateCodeRequest = {
      couponId: this.couponId,
      codeId: code,
      skip,
      limit,
    };
    this.couponProvider.invalidateCode(invalidateCodeRequest);
  }

  invalidateCodes(): void {
    const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
      data: {
        message: `Are you sure you want to invalidate all codes?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result !== false && result != null ),
        take(1))
      .subscribe(() => {
        const { skip, limit } = this.getSkipAndLimit();

        const invalidateCodeRequest: InvalidateCodesRequest = {
          couponId: this.couponId,
          skip,
          limit,
        };
        this.couponProvider.invalidateCodes(invalidateCodeRequest);
      });
  }

  downloadCodes(): void{
    const fileName = 'Campaign_' + this.name.value + `_codes_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
    const mappedCodes = this.couponCodes.map((code: CouponCodeRedemptionDetails) => {
      const codeMap = { ...code };
      codeMap.state = this.couponJournalEnumMapper[code.state].toLowerCase() as unknown as CouponJournalEnum;
      return codeMap;
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedCodes);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'test');
    XLSX.writeFile(wb, fileName);
  }

  controlCoupon(): void {
    const dialogText = `Do you really want to set the status to controlled?`;
    const data = {
      text: dialogText,
      type: 1,
    };

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result !== false && result != null ),
        take(1))
      .subscribe(() => {
        this.couponProvider.controlCoupon(this.couponId);
      });
  }

  navigateBackToList(): void {
    this.redirectToList.emit();
  }

  addFinanceParty(): void {
    // show a dialog to add a new financing name to the list of financing parties

    const dialogRef = this.dialog.open(AddFinancingPartyDialog);

    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result != null),
        take(1)
      )
      .subscribe((result: string) => {
        this.checkIfNewFinancingPartyAlreadyExists(result);
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    const { skip, limit } = this.getSkipAndLimit();
    this.couponProvider.dispatchGetCouponById$(this.couponId, skip, limit);
  }

  onSaveClick(): void {
    if (!this.validateCostSplitTypeSelected()) {
      return;
    }

    this.promptForSubmissionConfirmation();
  }

  private promptForSubmissionConfirmation(): void {
    const dialogText = this.generateDialogText();

    if (dialogText !== "") {
      const data: DialogData = { text: `${dialogText}<br><br>Are you sure?`, type: 1 };
      const dialogRef = this.dialog.open(ConfirmationDialog, { data });
      dialogRef
        .afterClosed()
        .pipe(
          filter((result) => result != null && result !== false),
          take(1))
        .subscribe(() => {
          this.submit();
        });

    } else {

      this.submit();

    }

  }

  private onInitSubscriptions(): void {
    this.getErrors = this.couponProvider.getCouponError$().pipe(
      filter(error => error != null)
    ).subscribe((httpError: HttpErrorResponse) => {
      let errorMessage = 'Internal Server error';

      if (httpError?.error?.message) {
        errorMessage = httpError?.error?.message;
        this.isFormSubmitted = false;
      }

      this.dialog.open(CouponErrorDialog, {
        data: {
          error: errorMessage,
        },
      });

    });

    this.productsSubscription = this.productsObservable$.pipe(
      filter((products => products != null))
    ).subscribe((products: string[]) => {
      this.whiteListedProducts = [...products];
      // initially add all products to coupon products array
      if (this.couponId == null || this.whiteListedProducts.length === this.products?.value.length) {
        // patch a '0' value for 'ALL' selection
        this.products.patchValue([0]);
      }

    });

    // Subscribe to user IDs changes
    this.userTokensSubscription = this.userTokens$
    .pipe(
      filter(userTokens => userTokens.length > 0),
      tap((userTokens: string[]) => {
        // Dispatch action to fetch user emails
        this.usersProvider.dispatchGetUsersEmails$(userTokens);
      })
    ).subscribe();


    this.userEmailsSubscription = this.usersProvider.getUsersEmails$().subscribe((userTokensToEmails: Record<string, string>) => {
      // Update the table with the new emails
      this.updateCouponCodesWithEmails(userTokensToEmails);
    });

    this.financingParties$ = this.couponProvider.selectFinancingParties().pipe(
      tap((financingParties: IFinancingParty[])=> {
        this.financingParties = financingParties;
      }),
      map(() => {
        return this.financingParties.map((party: IFinancingParty) => party.name);
      })
    );
  }

  private initCouponForm(couponInit: CouponForm): void {
    const coupon = { ...couponInit };

    this.couponForm = this.formBuilder.group({
      name: new FormControl(coupon.name, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      couponType: new FormControl(coupon.couponType, [Validators.required]),
      manualCodes: new FormControl(coupon?.codeName, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(20),
      ]),
      noOfCodes: new FormControl(coupon?.noOfCodes, [Validators.max(100000), Validators.min(1)]),
      effectType: new FormControl(coupon?.effectType, [Validators.required]),
      effectValue: new FormControl(coupon?.effectValue, [
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(0.01),
        Validators.required,
      ]),
      products: new FormControl(coupon.products),
      minPurchaseAmount: new FormControl(coupon.minPurchaseAmount, [
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(0.0),
        Validators.max(9999.99),
        Validators.required,
      ]),
      maxApplicationPerUser: new FormControl(coupon.maxApplicationPerUser, [
        Validators.min(0),
        Validators.max(9999999999),
        Validators.required,
      ]),
      maxApplicationOverAllUsers: new FormControl(coupon.maxApplicationOverAllUsers, [
        Validators.min(0),
        Validators.max(9999999999),
        Validators.required,
      ]),
      startFrom: new FormControl(coupon.startFrom, Validators.required),
      endAt: new FormControl(coupon.endAt, Validators.required),
      // Three direct financing parties
      costSplitType: new FormControl(coupon.costSplitType),
      financingPartyName1: new FormControl(coupon.financingParties?.[0]?.name),
      financingPartyAmount1: new FormControl(coupon.financingParties?.[0]?.amountOrPercentage, [
        Validators.min(0),
        Validators.pattern(/^\d+(\.\d{1,2})?$/),
      ]),
      financingPartyName2: new FormControl(coupon.financingParties?.[1]?.name),
      financingPartyAmount2: new FormControl(coupon.financingParties?.[1]?.amountOrPercentage, [
        Validators.min(0),
        Validators.pattern(/^\d+(\.\d{1,2})?$/),
      ]),
      financingPartyName3: new FormControl(coupon.financingParties?.[2]?.name),
      financingPartyAmount3: new FormControl(coupon.financingParties?.[2]?.amountOrPercentage, [
        Validators.min(0),
        Validators.pattern(/^\d+(\.\d{1,2})?$/),
      ]),
      comment: new FormControl(coupon.comment),
    });

    this.changeCouponEffectType(coupon?.effectType, true);

    this.couponForm.setValidators(Validators.compose([this.validateFields]));

    this.effectType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((effectType: string) => {
      if (effectType === CouponEffectTypeEnum.Percentage) {
        this.costSplitType.setValue('Percentage');
        this.costSplitType.disable();
      } else {
        this.costSplitType.enable();
      }
    });

    if (coupon.products.length === 0) {
      this.products.patchValue([0]);
    }
  }

  private initEditCouponById(): void {
    const { skip, limit } = this.getSkipAndLimit();
    this.isEditMode = true;
    this.loading$ =  combineLatest([
      this.couponProvider.getSuccessState$(),
      this.usersProvider.getLoadingState$()
    ]).pipe(
      map(([couponLoading, emailsLoading]) => couponLoading || emailsLoading),
    );

    this.couponProvider.dispatchGetCouponById$(this.couponId, skip, limit);


    this.getCouponDetails = combineLatest([
      this.couponProvider.getSelectedCoupon$(),
      this.couponProvider.selectTotalCouponDiscount(),
      this.adminUsers$])
    .pipe(
      filter(([coupon]) => coupon != null)
    ).subscribe(
      ([coupon, totalDiscount ,adminUsers]) => {

        // preserve the order
        if (this.availableCodes !== coupon.availableCodesCount) {
          this.couponProvider.dispatchTotalCouponDiscount(this.couponId);
        }

        // preserve the order
        this.setCouponMetaData(coupon, adminUsers);

        // this condition is used as to not initialize the form with every pagination
        if (!this.isFormInitialized) {
          this.initCouponForm(coupon);
          this.isFormInitialized = true;
        }

        this.disableFieldsAccordingToType(coupon.couponType, coupon.effectType);
        this.updateUserTokens(coupon.couponJournals);
        this.handleCostSplittingAmongFinancingParties(coupon, totalDiscount);
      }

    );
  }

  private submit(): void {
    this.error = undefined;
    this.isFormSubmitted = true;

    if (this.couponId != null) {
      const updatedCoupon = this.constructEditedCouponObj();
      if (!this.isViewMode && !equal(this.initialCoupon, updatedCoupon)) {
        this.couponProvider.editCoupon(updatedCoupon, this.couponId);
      } else {
        // edit coupon effect redirects to navigate so will do an else check incase no edit happened
        this.navigateBackToList();
      }
    } else {
      const newCoupon = this.constructAddCouponObj();
      this.couponProvider.createCoupon(newCoupon);
    }
  }

  private handleCostSplittingAmongFinancingParties(coupon: DetailedCouponResponseDTO, totalCouponDiscount: number): void {
    if (
      coupon?.financingParties == null ||
      coupon?.financingParties.length === 0 ||
      coupon?.costSplitType == null
    ) {
      return;
    }

    const totalDiscount = Math.round(totalCouponDiscount * 100) / 100; // Ensures precision to two decimal places.

    // Check if effectType and costSplitType are the same
    if (coupon.effectType === coupon.costSplitType) {
      this.splitCost(coupon, totalDiscount, coupon.effectValue);
      return;
    }

    // Handle specific mismatched configurations
    const isFixedEffectType = coupon.effectType === CouponEffectTypeEnum.Fixed;
    const isPercentageCostSplitType = coupon.costSplitType === CouponEffectTypeEnum.Percentage;

    if (isFixedEffectType && isPercentageCostSplitType) {
      this.splitCost(coupon, totalDiscount, 100);
    } else if (!isFixedEffectType && !isPercentageCostSplitType) {
      throw new Error('Invalid Coupon Configuration');
    } else {
      throw new Error('Unhandled Coupon Configuration');
    }
  }

  private splitCost(coupon: DetailedCouponResponseDTO, totalDiscount: number, denominator: number): void {
    for (let i = 0; i < FINANCING_PARTIES_COUNT; i++) {
      const financingPartyName = this.couponForm.get(`financingPartyName${i + 1}`).value;
      const financingPartyAmount = this.couponForm.get(`financingPartyAmount${i + 1}`).value;

      if (
        financingPartyName != null &&
        financingPartyAmount != null &&
        financingPartyName.trim() !== '' &&
        financingPartyAmount >= 0
      ) {
        const amountOrPercentage = parseFloat((coupon.financingParties[i]?.amountOrPercentage ?? 0).toFixed(2));
        this.couponFinancingPartyTotal[i] =
          Math.round(((amountOrPercentage * totalDiscount) / denominator) * 100) / 100;
      }
    }
  }

  private isAnyFinancingPartyInComplete(): boolean {
    // check if any financing party is incomplete (meaning it has a name but no amount or vice versa)
    for (let i = 0; i < FINANCING_PARTIES_COUNT; i++) {
      const financingPartyName = this.couponForm.get(`financingPartyName${i + 1}`)?.value;
      const financingPartyAmount = this.couponForm.get(`financingPartyAmount${i + 1}`)?.value;

      const isNameInvalid = financingPartyName == null || financingPartyName.trim() === '';
      const isAmountValid = financingPartyAmount != null && financingPartyAmount >= 0;
      if (isNameInvalid && isAmountValid) {
        return true;
      }
    }
    return false;
  }

  private validateCostSplitTypeSelected(): boolean {
    if (this.costSplitType.value == null && !this.areAllFinancingPartiesEmpty()) {
      const data = {
        text: `Please select a cost split type.`,
        type: 2
      }
      this.dialog.open(ConfirmationDialog, { data });
      return false;
    }
    return true;
  }

  private areAllFinancingPartiesEmpty(): boolean {
    for (let i = 0; i < FINANCING_PARTIES_COUNT; i++) {
      const financingPartyName = this.couponForm.get(`financingPartyName${i + 1}`)?.value;
      const financingPartyAmount = this.couponForm.get(`financingPartyAmount${i + 1}`)?.value;

      const isNameEmpty = financingPartyName == null || financingPartyName.trim() === '';
      const isAmountEmpty = financingPartyAmount == null || financingPartyAmount === '';

      if (!isNameEmpty || !isAmountEmpty) {
        return false;
      }
    }
    return true;
  }

  // Define a custom validator that compares the values of the FormControls
  private validateFields(form: FormGroup) {
    const minPurchaseAmount = form.get('minPurchaseAmount').value;
    const effectValue = form.get('effectValue').value;
    const effectType = form.get('effectType').value;
    const startFrom = form.get('startFrom').value;
    const endAt = form.get('endAt').value;

    let invalidDates = false;
    if (startFrom != null && endAt != null) {
      if (!moment(endAt).isAfter(moment(startFrom))) {
        invalidDates = true;
      }
      if (moment(endAt).isAfter(moment('2032-12-31'))) {
        invalidDates = true;
      }
      if (moment(startFrom).isAfter(moment('2032-12-31'))) {
        invalidDates = true;
      }
      if (moment(startFrom).isBefore(moment('2022-12-31'))) {
        invalidDates = true;
      }
    }

    // Compare the values of the fields and return an error if necessary
    if (effectType === CouponEffectTypeEnum.Fixed && minPurchaseAmount < effectValue) {
      return { invalidDates, minPurchaseAmountToSmall: true };
    }

    if (invalidDates) {
      return { invalidDates };
    }
    return null;
  }

  private setCouponMetaData(coupon: DetailedCouponResponseDTO, adminUsers: AdminUsersResponse): void {
    this.isCouponControlled = coupon.controlled != null && coupon.controlled;
    this.isCouponArchived = coupon.archived != null && coupon.archived;
    this.isCouponRedeemed = coupon.isRedeemed;
    this.redeemState = coupon.isRedeemed
      ? RedeemStateEnum[RedeemStateEnum.REDEEMED]
      : RedeemStateEnum[RedeemStateEnum.OPEN];
    this.availableCodes = coupon.availableCodesCount;
    this.couponCodes = coupon.couponJournals;
    this.dataSource = new MatTableDataSource<CouponCodeRedemptionDetails>(this.couponCodes);
    this.totalRecords = coupon.totalNumberRecords;
    this.showInvalidateCodesBtn = coupon.availableCodesCount !== 0;
    this.initialCoupon = this.initialCouponObject(coupon);

    if (adminUsers != null && adminUsers.length > 0) {
      this.adminUserDetails = {
        createdBy: adminUsers.find((user) => user.id === coupon.createdBy)?.name ?? coupon.createdBy,
        createdAt: coupon.createdAt,
        modifiedBy: adminUsers.find((user) => user.id === coupon.updatedBy)?.name ?? coupon.updatedBy,
        modifiedAt: coupon.updatedAt,
      };
    }
  }

  private disableFieldsAccordingToType(couponType: CouponTypeEnum, effectType: CouponEffectTypeEnum): void {
    this.couponType.disable();
    this.manualCodes.disable();
    this.noOfCodes.disable();
    this.effectType.disable();

    if (couponType === CouponTypeEnum.Multi) {
      this.maxApplicationPerUser.disable();
      this.maxApplicationOverAllUsers.disable();
    }

    if (effectType === CouponEffectTypeEnum.Percentage) {
      this.costSplitType.setValue('Percentage');
      this.costSplitType.disable();
    }

    if (this.isCouponRedeemed) {
      this.effectValue.disable();
      this.minPurchaseAmount.disable();
      this.maxApplicationPerUser.disable();
      this.maxApplicationOverAllUsers.disable();
      this.startFrom.disable();
      this.endAt.disable();
    }
  }

  // Update user tokens based on visible data
  private updateUserTokens(couponJournals: CouponCodeDTO[]): void {
    const allUserTokens = couponJournals
      .filter((item) => item.userId != null && item.userId !== '')
      .map((filtered) => filtered.userId);
    const uniqueUserTokens = [...new Set(allUserTokens)];
    this.userTokens$.next(uniqueUserTokens);
  }

  private updateCouponCodesWithEmails(userTokensToEmails: Record<string, string>): void {
    this.couponCodes = this.couponCodes.map((coupon) => {

      const updatedCoupon = { ...coupon };

      if (updatedCoupon.userId != null && updatedCoupon.userId !== '') {
        updatedCoupon.email = userTokensToEmails[updatedCoupon.userId] ?? '-';
      } else {
        updatedCoupon.email = '-'; // Default for empty userId
      }

      return updatedCoupon;
    });

    this.dataSource.data = [...this.couponCodes];
  }

  private checkIfNewFinancingPartyAlreadyExists(name: string): void {
    // make the equality check case insensitive
    if (this.financingParties.some((party) => party.name.toLowerCase() === name.toLowerCase())) {
      // show an error dialog
      this.dialog.open(CouponErrorDialog, {
        data: {
          error: `The financing party '${name}' already exists.`,
        },
      });
    } else {
      this.couponProvider.addFinanceParty(name);
      // show a success dialog
      this.dialog.open(ConfirmationDialog, {
        data: {
          text: `Finance party added successfully.`,
          type: 2,
        },
      });
    }
  }

  private constructEditedCouponObj(): UpdateCouponDTO {
    const updatedCoupon: UpdateCouponDTO = {
      name: this.name.value,
      // remove the 'All' from products selection menu
      products: this.products.value.filter((product: number) => product !== 0),
      minPurchaseAmount: this.minPurchaseAmount.value,
      startFrom: this.startFrom.value,
      endAt: this.endAt.value,
      effectType: this.effectType.value,
      effectValue: this.effectValue.value,
      maxApplicationOverAllUsers: this.maxApplicationOverAllUsers.value,
      maxApplicationPerUser: this.maxApplicationPerUser.value,
      costSplitType: this.costSplitType.value,
      comment: this.comment.value,
      couponType: this.couponType.value,
    };

    updatedCoupon.financingParties = [];

    if (this.financingPartyName1.value && this.financingPartyAmount1) {
      updatedCoupon.financingParties[0] = {
        name: this.financingPartyName1.value,
        amountOrPercentage: this.financingPartyAmount1.value,
      };
    }
    if (this.financingPartyName2.value && this.financingPartyAmount2) {
      updatedCoupon.financingParties[1] = {
        name: this.financingPartyName2.value,
        amountOrPercentage: this.financingPartyAmount2.value,
      };
    }
    if (this.financingPartyName3.value && this.financingPartyAmount3) {
      updatedCoupon.financingParties[2] = {
        name: this.financingPartyName3.value,
        amountOrPercentage: this.financingPartyAmount3.value,
      };
    }
    return updatedCoupon;
  }

  private constructAddCouponObj(): CreateCouponDTO {
    const newCoupon: CreateCouponDTO = {
      name: this.name.value,
      couponType: this.couponType.value,
      // remove the 'All' from products selection menu
      products: this.products.value.filter((product: number) => product !== 0),
      minPurchaseAmount: this.minPurchaseAmount.value,
      startFrom: this.startFrom.value,
      endAt: this.endAt.value,
      effectType: this.effectType.value,
      effectValue: this.effectValue.value,
      maxApplicationOverAllUsers: this.maxApplicationOverAllUsers.value,
      maxApplicationPerUser: this.maxApplicationPerUser.value,
      costSplitType: this.costSplitType.value,
      comment: this.comment.value,
    };

    // coupon type check
    if (this.couponType?.value === CouponTypeEnum.Single) {
      newCoupon.codes = [this.manualCodes.value.toLowerCase()];
    } else {
      newCoupon.noOfCodes = this.noOfCodes.value;
    }

    // finance parties check
    newCoupon.financingParties = [];

    if (this.financingPartyName1.value && this.financingPartyAmount1) {
      newCoupon.financingParties[0] = {
        name: this.financingPartyName1.value,
        amountOrPercentage: this.financingPartyAmount1.value,
      };
    }
    if (this.financingPartyName2.value && this.financingPartyAmount2) {
      newCoupon.financingParties[1] = {
        name: this.financingPartyName2.value,
        amountOrPercentage: this.financingPartyAmount2.value,
      };
    }
    if (this.financingPartyName3.value && this.financingPartyAmount3) {
      newCoupon.financingParties[2] = {
        name: this.financingPartyName3.value,
        amountOrPercentage: this.financingPartyAmount3.value,
      };
    }

    return newCoupon;
  }

  private getSkipAndLimit(): { skip: number; limit: number } {
    const skip = this.pageIndex * this.pageSize;
    const limit = this.pageSize;
    return { skip, limit };
  }

  private initialCouponObject(coupon: DetailedCouponResponseDTO): UpdateCouponDTO {
    const initialCoupon: UpdateCouponDTO = {
      couponType: coupon.couponType,
      effectType: coupon.effectType,
      effectValue: coupon.effectValue,
      endAt: coupon.endAt,
      maxApplicationOverAllUsers: coupon.maxApplicationOverAllUsers,
      maxApplicationPerUser: coupon.maxApplicationPerUser,
      minPurchaseAmount: coupon.minPurchaseAmount,
      name: coupon.name,
      startFrom: coupon.startFrom,
      financingParties: coupon.financingParties,
      costSplitType: coupon.costSplitType,
      comment: coupon.comment,
      products: coupon.products != null ? [...coupon.products] : [],
    };

    return initialCoupon;
  }

  private generateDialogText(): string{

    let dialogText = '';

    if (this.noOfCodes.valid && this.noOfCodes.value > 500 && !this.isEditMode) {
      dialogText += `You have selected to generate ${this.noOfCodes.value} codes. Once the codes are generated this field will no longer be editable.<br>`;
    }

    if (this.effectType.value === CouponEffectTypeEnum.Percentage && this.effectValue.value > 10) {
      dialogText += `You have selected ${this.effectValue.value}% as a discount value.<br>`;
    }

    if (this.effectType.value === CouponEffectTypeEnum.Fixed && this.effectValue.value > 10) {
      dialogText += `<br>You have selected CHF ${this.effectValue.value} as a discount price amount.<br>`;
    }

    if (this.isAnyFinancingPartyInComplete()) {
      dialogText += `<br>One or more financing parties are incomplete. If you proceed, the coupon will be saved without the incomplete financing parties.<br>`;
    }

    return dialogText;
  }

}
